import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { IntercomProvider } from 'react-use-intercom';

import { LandingContextProvider } from 'holded/lib/context/landingContext';
import getStrapiMedia from 'holded/lib/media';
import { ConsentProps, getWhatsappData, GlobalData, LanguageCode, Metadata } from 'holded/modules/cms/domain/page';
import { NextPageWithLayout } from 'holded/shared/ui/types/next';

import 'holded/styles/globals.scss';

const DEFAULT_CONSENT_PROPS: ConsentProps[] = [
  'consent',
  'default',
  {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'denied',
    wait_for_update: 500,
  },
];

// Can't use arrow func + destructuring as Google expects arguments objects in dataLayer (not an array of arguments).
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function gtag(...rest: unknown[]) {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer?.push(arguments);
}

type MyAppProps = {
  globalData?: GlobalData;
  metadata?: Metadata;
  page: string;
  currentLocale: LanguageCode;
};

declare global {
  interface Window {
    dataLayer?: unknown[];
  }
}

const GoogleTagManager = dynamic(() => import('holded/shared/ui/components/GoogleTagManager'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // By default do not retry failed queries
      retry: false,
      // If a user leaves the application and returns to stale data, React Query automatically requests fresh data
      // in the background. We disable this globally, set per-query when needed.
      refetchOnWindowFocus: false,
    },
  },
});

const MyApp = ({ Component, pageProps }: AppProps<MyAppProps> & { Component: NextPageWithLayout }) => {
  const router = useRouter();
  const [mobile, setMobile] = useState(false);

  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    setMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

    const mainDataLayer = {
      pageTypeName: pageProps?.page || null,
      url: process.env.NEXT_PUBLIC_URL + router.asPath,
      event: 'pageview',
      page: process.env.NEXT_PUBLIC_URL + router.asPath,
      pageCategory: 'Main_Landing',
    };

    gtag(...DEFAULT_CONSENT_PROPS);

    window.onload = () => {
      window.dataLayer?.push(mainDataLayer);
    };
  }, []);

  const whatsappData = mobile ? getWhatsappData(pageProps.currentLocale) : undefined;

  const showWhatsapp = mobile && !!whatsappData;

  return (
    <>
      <Head>
        <title key="title">{pageProps?.metadata?.metaTitle}</title>
        <meta charSet="utf-8" />
        {pageProps?.globalData?.global?.attributes?.favicon?.data?.attributes?.url && (
          <link
            rel={'shortcut icon'}
            href={getStrapiMedia(pageProps?.globalData?.global?.attributes?.favicon?.data?.attributes?.url)}
            key="favicon"
          />
        )}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=1" />
      </Head>

      <QueryClientProvider client={queryClient}>
        <IntercomProvider
          appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? ''}
          initializeDelay={mobile ? 5000 : 3000}
          shouldInitialize={!!process.env.NEXT_PUBLIC_INTERCOM_APP_ID}
        >
          {showWhatsapp && <FloatingWhatsApp {...whatsappData} />}
          <LandingContextProvider>{getLayout(<Component {...pageProps} />)}</LandingContextProvider>
        </IntercomProvider>
      </QueryClientProvider>

      <GoogleTagManager id={process.env.NEXT_PUBLIC_GTM_ID} />
    </>
  );
};

export default MyApp;
