const getStrapiMedia = (url: string) => {
  if (url == null) {
    return '';
  }

  if (url.startsWith('http') || url.startsWith('//')) {
    return url;
  }

  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${url}`;
};

export default getStrapiMedia;
