var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d64850baaf8b0a4e6bfe09d1bbf131818bd0d803"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  beforeSendTransaction(event) {
    // filter out UnhandledRejection errors that have no information
    if (event?.exception?.values?.length === 1) {
      const e = event.exception.values[0];
      if (e.type === 'UnhandledRejection' && e.value.startsWith('Non-Error promise rejection captured with value')) {
        return null;
      }
    }
  },
  ignoreErrors: [
    //Cookies component
    "undefined is not an object (evaluating 'yn.bannerGroup.html')",
    "undefined is not an object (evaluating 'e.Domain')",
    "undefined is not an object (evaluating 'r.DomainData')",
    "Cannot read properties of undefined (reading 'Domain')",
    "undefined is not an object (evaluating 'yn.bannerGroup.html')",
    "Cannot read properties of undefined (reading 'config')",
    "Cannot read properties of undefined (reading 'loaded')",
    //Browser extension errors
    'jwplayer is not defined',
    'Illegal invocation',
    //iOS fetch bug
    'Load failed',
  ],
});
