import React, { FC, createContext, useContext, useState, PropsWithChildren, useCallback } from 'react';

import { GlobalData, LanguageCode, Page } from 'holded/modules/cms/domain/page';

type LandingContextProps = {
  pricingPlanCategory: string;
  updatePricingPlanCategory: (plan: string) => void;
  pageData: Page;
  updatePageData: (page: Page) => void;
  globalData: GlobalData;
  updateGlobalData: (global: GlobalData) => void;
  currentLocale: string;
  updateCurrentLocale: (locale: string) => void;
  defaultLocale: LanguageCode;
  updateDefaultLocale: (locale: LanguageCode) => void;
};

const initialState: LandingContextProps = {
  pricingPlanCategory: '',
  updatePricingPlanCategory: () => null,
  pageData: {},
  updatePageData: () => null,
  globalData: {
    global: {},
    header: {},
    footer: {},
  },
  updateGlobalData: () => null,
  currentLocale: '',
  updateCurrentLocale: () => null,
  defaultLocale: 'en',
  updateDefaultLocale: () => null,
};

const LandingContext = createContext(initialState);

const LandingContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [pricingPlanCategory, setPricingPlanCategory] = useState<string>('');
  const [pageData, setPageData] = useState<Page>(initialState.pageData);
  const [globalData, setGlobalData] = useState<GlobalData>(initialState.globalData);
  const [currentLocale, setCurrentLocale] = useState<string>('/');
  const [defaultLocale, setDefaultLocale] = useState<LanguageCode>('en');

  const updatePricingPlanCategory = (plan: string) => {
    setPricingPlanCategory(plan);
  };

  const updatePageData = useCallback(
    (page: Page) => {
      setPageData(page);
    },
    [setPageData],
  );

  const updateGlobalData = (global: GlobalData) => {
    setGlobalData(global);
  };

  const updateCurrentLocale = (locale: string) => {
    setCurrentLocale(locale == 'en' ? '/' : '/' + locale + '/');
  };

  const updateDefaultLocale = (locale: LanguageCode) => {
    setDefaultLocale(locale);
  };

  return (
    <LandingContext.Provider
      value={{
        pricingPlanCategory,
        updatePricingPlanCategory,
        pageData,
        updatePageData,
        globalData,
        updateGlobalData,
        currentLocale,
        updateCurrentLocale,
        defaultLocale,
        updateDefaultLocale,
      }}
    >
      {children}
    </LandingContext.Provider>
  );
};

const useLandingContext = () => {
  const context = useContext(LandingContext);

  if (context === null) {
    throw new Error('useLandingContext must be used within a LandingContextProvider');
  }

  return context;
};

export { LandingContextProvider, useLandingContext };
